<template>
  <keep-alive>
    <video controls="controls" preload="metadata">
      <source :src="videoSrc" />
    </video>
  </keep-alive>
</template>

<script>

export default {
  props: {
    node: {
      type: Object,
      required: true
    },
    upil: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    inputName () {
      return this.node.input.name
    },
    stateInputValue () {
      return this.state[this.inputName]
    },
    videoSrc () {
      return `${this.stateInputValue}#t=.01`
    }
  }
}
</script>

<style scoped>
video {
  max-height: 300px;
  max-width: 100%;
}
</style>
